/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */


// All your imports required for the config here BEFORE this line
import '@plone/volto/config';
import { FrontPageTemplate } from './customizations/components/manage/Blocks/Listing';
import { FrontPageTemplateTermin } from './customizations/components/manage/Blocks/Listing';

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
    },
  };
  config.settings = {
    ...config.settings,
    logo: '/customisations/components/theme/Logo/Logo.svg',
  };
  config.blocks.requiredBlocks = [];
  //{ console.log(config.settings) }
  config.blocks.blocksConfig.listing.variations = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: "FrontPageListing",
      title: "FrontPage",
      template: FrontPageTemplate,
    },
    {
      id: "FrontPageListingTermin",
      title: "FrontPageTermin",
      template: FrontPageTemplateTermin,
    },
  ];
  return config;
}
